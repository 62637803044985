import React from 'react'

import Footer from './Footer'
import avatar from '../assets/images/avatar.jpg'

const Header = () => {
  return (
    <header id="header">
      <div className="inner">
        <a href="#/" className="image avatar">
          <img src={avatar} alt="" />
        </a>
        <h1>
          <strong>Serkan Gunes</strong><br/>
          <p>Senior Software Developer and Architect</p>
        </h1>
      </div>
      <Footer />
    </header>
  )
}

export default Header
